// this file contain the style for pricing components
/*****************************
	--- PRICING
*****************************/
.pricing{
  padding: 30px;
  background: #fff;
  position: relative;
  border-radius: 4px;
  &:before{
    content: "";
    height: 3px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-radius: 4px 4px 0 0;
    background: $mcolor4;
  }
  .pricing--title{
    text-align: center;
    font-size: 24px;
    border-bottom: 1px solid $borderColor;
    padding-bottom: 23px;
    line-height: 32px;
    font-weight: 500;
  }
  .pricing--price{
    text-align: center;
    padding-top: 26px;
    margin-bottom: 36px;
    sup{
      font-size: 18px;
      color: #000;
      margin-right: -4px;
    }
    .ammount{
      font-size: 36px;
      color: #000;
      line-height: 32px;
    }
  }
  .pricing--features{
    ul{
      li{
        position: relative;
        padding-left: 22px;
        font-size: 16px;
        line-height: 36px;
        span.lnr{
          font-size: 18px;
          color: #555;
          margin-left: 5px;
        }
        &:before{
          content: "";
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 7px;
          height: 7px;
          background: #c5cad4;
          border-radius: 200px;
          position: absolute;
        }
      }
    }
  }
  .pricing--btn{
    display: block;
    text-align: center;
    line-height: 50px;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    margin-top: 33px;
    font-weight: 500;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      background: #000;
      left: 0;
      transition: $transition;
      top: 0;
      opacity: 0;
      visibility: hidden;
    }
    &:hover:before{
      visibility: visible;
      opacity: 0.05;
    }
  }
  &.red {
    .pricing--btn{
      background: $mcolor4;
    }
    &:before{
      background: $mcolor4;
    }
  }
  &.blue{
    .pricing--btn{
      background: $pcolor;
    }
    &:before{
      background: $pcolor;
    }
  }
  &.yellow{
    .pricing--btn{
      background: $mcolor2;
    }
    &:before{
      background: $mcolor2;
    }
  }
}
