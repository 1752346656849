// this file contains the typography scss
h1{
  font-size: 36px;
}
h2{
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4{
  font-size: 22px;
}
h5{
  font-size: 20px;
}
h6{
  font-size: 18px;
}