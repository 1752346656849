//theme contains the css for colors that is spacific for this theme
//any change on this file will cause chang of theme color
/*****************************
	-- THEME
*****************************/
//primary color
$pcolor: #0674ec;

//secondary color
$scolor: #7347c1;

// multicolors for different multi color section and color diversity
$mcolor1: #77de51;
$mcolor2: #feb71d;
$mcolor3: #58c9e9;
$mcolor4: #ff6a6d;

// text colors
$title-color: #000000;
$text-color: #555555;
$text-color2: #747686;

// section background
$section-bg: #eff1f5;

// gradient
//$gradient: linear-gradient(to left, $scolor 0%,  $pcolor 100%);
@mixin gradient{
  background-image: linear-gradient(to left, $scolor 0%,  $pcolor 100%);
  /* Fallback (could use .jpg/.png alternatively) */
  background-color: $scolor;

  /* SVG fallback for IE 9 (could be data URI, or could use filter) */
  background: $pcolor;

  /* Safari 4, Chrome 1-9, iOS 3.2-4.3, Android 2.1-3.0 */
  background-image:
          -webkit-gradient(linear, left top, right top, from($scolor), to($pcolor));

  /* Safari 5.1, iOS 5.0-6.1, Chrome 10-25, Android 4.0-4.3 */
  background-image:
          -webkit-linear-gradient(left, $scolor, $pcolor);

  /* Firefox 3.6 - 15 */
  background-image:
          -moz-linear-gradient(left, $scolor, $pcolor);

  /* Opera 11.1 - 12 */
  background-image:
          -o-linear-gradient(left, $scolor, $pcolor);

  /* Opera 15+, Chrome 25+, IE 10+, Firefox 16+, Safari 6.1+, iOS 7+, Android 4.4+ */
  background-image:
          linear-gradient(to right, $scolor, $pcolor);
}
// border color
$borderColor: #ececec;

// sectionbg
$bgcolor: #eff1f5;
$bgcolor2: #f6f7fb;
$bgcolor3: #f0f1f5;
