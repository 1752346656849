@import "theme";
@import "mixins";
/*****************************
	-- GENERAL
*****************************/

// transition
$transition: 0.3s;

// primary colors
.pcolor{
  color: $pcolor;
}
.scolor{
  color: $scolor;
}

/* Background Image */
.bgimage{
  position: relative;
  &:before{
    z-index:1;
  }
}
.content_above{
  position: relative;
  z-index: 2;
}
/* Background image holder */
.bg_image_holder{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  z-index: 0;
  transition: opacity .3s linear;
  -webkit-transition: opacity .3s linear;
  opacity: 0;
  img{
    display: none;
  }
}
.or{
  width: 100%;
  background: $borderColor;
  height: 1px;
  margin-bottom: 33px;
  position: relative;
  &:before{
    content: 'Or';
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    color: $pcolor;
    font-weight: 500;
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
// multi colors
.mcolor1{
  color: $mcolor1;
}
.mcolor2{
  color: $mcolor2;
}
.mcolor3{
  color: $mcolor3;
}
.mcolor4{
  color: $mcolor4;
}


// multi colors backgroun
.pcolorbg{
  background: $pcolor;
}
.scolorbg{
  background: $scolor;
}
.mcolorbg1{
  background: $mcolor1;
}
.mcolorbg2{
  background: $mcolor2;
}
.mcolorbg3{
  background: $mcolor3;
}
.mcolorbg4{
  background: $mcolor4;
}

/* tooltip */
.tooltip{
  display: block;
  .tooltip-inner{
    font-family: "Quicksand", sans-serif;
    font-size: 14px;
    text-align: left;
    display: block;
  }
}
/*.highlighted{
  background: $pcolor;
}*/

//.row{
//  &:before, &:after{
//    content: '';
//    display: table;
//  }
//}
// SECTION PADDING
.section--padding{
  padding-top: 120px;
  padding-bottom: 120px;
}
.section--padding2 {
  padding: 70px 0 120px;
}
.section-title{
  text-align: center;
  padding-bottom: 53px;
  h1{
    font-weight: 400;
    margin-bottom: 24px;
    .highlighted{
      color: $pcolor;
    }
  }
  p{
    padding: 0 230px;
    line-height: 30px;
    margin-bottom: 0;
  }
}

/* Input with icons */
.input_with_icon{
  position: relative;
  span{
    position: absolute;
    right: 20px;
    top: 50%;
    color: #747b86;
    transform:translateY(-50%)
  }
}

//section product title area
.product-title-area {
  &:before, &:after{
    display: table;
    content:"";
    clear: both;
  }
  background: #fff;
  padding: 32px 30px 32px;
  margin-bottom: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px transparentize(#000,.9);
  .product__slider-nav{
    float: right;
    background: $scolor;
    span{
      line-height: 45px;
      color: #fff;
      cursor: pointer;
      width: 52px;
      display: inline-block;
      text-align: center;
    }
  }
  .date_area{
    p{
      float: left;
    }
    float: right;
    .input_with_icon{
      max-width: 158px;
    }
    .input_with_icon, .select-wrap{
      float:left;
      margin-right: 10px;
    }
    .select-wrap{
      select{
        height: 47px;
      }
    }

    .btn:hover{
      background: $pcolor;
      color: #fff;
    }
  }
}
.product__title{
  float: left;
  h2{
    font-weight: 400;
    line-height: 45px;
  }
}
.filter__menu{
  float: right;
  position: relative;
  margin-top: 10px;
  p{
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
    color: #747b86;
    margin-right: 15px;

  }
  .filter__menu_icon{
    display: inline-block;
    vertical-align: middle;
    margin-top: 6px;
    width: 17px;
    svg{
      height: 16px;
      cursor: pointer;
      &:hover{
        circle{
          fill: #0673ec;
        }
      }
      circle{
        fill: #a3b1c4;
      }
    }
    .filter_dropdown{
      position: absolute;
      min-width: 183px;
      padding: 20px 27px;
      top: 45px;
      z-index: 3;
      background: #fff;
      right: -15px;
      left: auto;
      box-shadow: 0 5px 40px transparentize(#52555a, .8);
     // display: none;
      &.active{
        display: block;
      }
      &:before{
        position: absolute;
        content: "";
        border-bottom: 10px solid #fff;
        border-left: 10px solid transparent;
        right: 15px;
        bottom: 100%;
        border-right: 10px solid transparent;
      }
      li{
        a{
          color: #747b86;
          display: block;
          transition: $transition;
          line-height: 28px;
          &:hover{
            color: $pcolor;
          }
        }
      }
    }
  }
}

// transparent bg
.transparent{
  background: transparent;
}

// for rounded area
.rounded{
  border-radius: 200px;
}

//tags css
.tags{
  ul >li{
    display: inline-block;
  }
}
.tags--round{
  ul > li{
    a{
      color: #868c96;
      line-height: 28px;
      background: #f1f3f6;
      display: inline-block;
      padding: 0 17px;
      margin-right: 4px;
      border-radius: 50px;
      transition: $transition;
      &:hover{
        background: $scolor;
        color: #fff;
      }
    }
  }
}

// background colors
.bgcolor{
  background: $bgcolor;
}
.bgcolor2{
  background: $bgcolor2;
}

// dropdown


// range slider
.range-slider.ui-slider{
  height:6px;
  background: #efedf3;
  border-radius: 6px;
  .ui-slider-range{
    background: $scolor;
  }
  .ui-slider-handle{
    height: 18px;
    width: 18px;
    background: $pcolor;
    border-radius: 50px;
    top: -6px;
    z-index: 2;
    &:focus{
      outline: 0;
    }
    &:before{
      content: '';
      position: absolute;
      height: calc(100% - 8px);
      width: calc(100% - 8px);
      background: #fff;
      left: 4px;
      top: 4px;
      z-index: -1;
      border-radius: 50%;
    }
  }
}
.price-ranges{
  padding-top: 34px;
  text-align: center;
  span{
    line-height: 32px;
    display: inline-block;
    background: transparentize($scolor, .9);
    width: 80px;
    color: $scolor;
  }
}


// rating
.rating{
  display: inline-block;
  vertical-align: middle;
  ul{
    float: left;
    padding-bottom: 0;
    li{
      float: left;
      margin-right: 3px;
      span.fa-star, span.fa-star-half-o{
        color: #ffc000;
      }
      span.fa-star-o{
        color: #7e7e7e;
      }
    }
  }
  .rating__count{
    display: inline-block;
    color: #6f7d8d;
    vertical-align: middle;
    margin-left: 5px;
  }
}

// custom radio button
.custom-checkbox2{
  input[type='checkbox']{
    display: none;
    &:checked + label span:before{
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
  label{
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    line-height: 36px;
    cursor: pointer;
    text-transform: none;
    span.circle{
      height: 20px;
      margin-right: 12px;
      vertical-align: middle;
      width: 20px;
      background: #fff;
      display: inline-block;
      border: 4px solid #e6e9ed;
      border-radius: 50%;
      position: relative;
      &:before{
        content: '\f00c';
        font-family: 'FontAwesome',sans-serif;
        position: absolute;
        height: 20px;
        width: 20px;
        text-align: center;
        font-size: 12px;
        bottom: -4px;
        transition: 0.2s;
        left: -4px;
        opacity: 0;
        transform: scale(0.5);
        border-radius: 50%;
        background: $pcolor;
        color: #fff;
        line-height: 20px;
      }
    }
  }
}
.custom-radio{
  @extend .custom-checkbox2;
  input[type='radio']{
    display: none;
    &:checked + label span:before{
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
  label span.circle {
    &:before{
      content: "";
      background: #fff;
      border: 4px solid $pcolor;
    }
  }
  &.custom_radio--big{
    span.circle{
      height: 30px;
      width: 30px;
      border-width: 5px;
      &:before{
        width: 30px;
        height: 30px;
        font-size: 16px;
        line-height: 30px;
        border-width: 5px;
      }
    }
  }
}

.radio-group{
  .label{
    display: block;
    text-align: left;
  }
  .custom-radio{
    display: inline-block;
  }
  .custom-radio+.custom-radio{
    margin-left: 30px;
  }
}

/* custom checkbox area css*/
.custom_checkbox{
  position: relative;
  margin-top: 10px;
  input[type='checkbox']{
    display: none;
    &:checked + label .shadow_checkbox{
      border: transparent;
      &:before{
        visibility: visible;
      }
    }
  }
  label{
    margin-bottom: 0;
    .label_text{
      font-weight: 400;
      color: #555;
      display: inline-block;
      vertical-align: middle;
      margin-left: 11px;
    }
    .shadow_checkbox{
      height: 18px;
      width: 18px;
      top: 0;
      border: 1px solid #d3d3d3;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      border-radius: 2px;
      &:before{
        position: absolute;
        height:100%;
        width: 100%;
        top: 0;
        content: '\f00c';
        font-size: 12px;
        visibility: hidden;
        text-align: center;
        color: #fff;
        line-height: 18px;
        font-family: FontAwesome, sans-serif;
        background: $pcolor;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
      }
    }
  }
}
/* custom upload field */
.custom_upload{
  p{
    font-weight: 500;
    font-size: 16px;
    color: #000;
    margin-bottom: 12px;
    span{
      font-size: 15px;
      line-height: 26px;
      font-weight: 400;
      color: #555;
    }
  }
  input[type='file']{
    display: none;
  }
}
/* cardify any area */
.cardify{
  @include cardify;
}

/* common toggle module css */
.toggle_title{
  display: block;
  padding: 30px 30px 25px;
   span.lnr{
     float: right;
     line-height: 20px;
     font-size: 14px;
   }
   h4{
     font-size: 22px;
   }
}

// single social area css goes here
.social_icon{
  display: inline-block;
  span{
    display: inline-block;
    line-height: 45px;
    width: 45px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    text-align: center;
    border-radius: 50%;
    font-size: 16px;
    color: #fff;
  }
  span.fa-facebook{
    background: #395799;
  }

  span.fa-twitter{
    background: #19b2f5;
  }
  span.fa-google-plus{
    background: #f05b44;
  }
  span.fa-behance{
    background: #0057ff;
  }
  span.fa-dribbble{
    background: #ea4c89;
  }
}

// input group
.input-group{
  .input-group-addon{
    background: none;
    border: none;
    font-size: 18px;
    color: #555;
    padding-left: 0;
  }
}

/* content area title */
.content_title{
  @extend .cardify;
  padding: 30px;
  border-radius: 4px;
  margin-bottom: 30px;
}

/* video modal css */
.video_modal{
  .modal-content{
    iframe{
      width: 100%;
      height: 450px;
    }
  }
}
#myModal.modal.fade .modal-dialog {
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -60%);
  -ms-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
}
@include customScrollbar(messages);
@include customScrollbar(offcanvas-menu);