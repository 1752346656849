/*****************************
	--- LISTS STYLE
*****************************/
 //ol{
 //  padding-bottom: 25px;
 //  padding-left: 17px;
 //  li{
 //    list-style-type: decimal;
 //    font-size: 16px;
 //    line-height: 34px;
 //  }
 //}
 //ul {
 //  li:before {
 //    margin-right: 15px;
 //  }
 //}