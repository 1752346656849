// this _btn.scss file contains all the different btn style used all across the site. any specific btn can be modified very easily from this file without
// interfering any other css

/*****************************
	---BUTTON
*****************************/
@import "../common/theme";
.btn--round{
  border-radius: 100px;
}
.btn{
  background: $pcolor;
  padding: 0;
  margin: 0;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
  box-shadow: 0 4px 30px transparentize(#000, .9);
  transition: $transition;
  border: none;
  position: relative;
  &:before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background: #000;
    left: 0;
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    top: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 0;
    border-radius: 4px;
  }
  &:focus,&:active{
    color: #fff;
    outline: 0;
  }
  &:hover{
    color: #fff;
  }
  &:hover:before{
    opacity: 0.07;
    visibility: visible;
  }
  &.btn--round:hover:before{
    border-radius: 200px;
  }
}
.btn--bordered,
.btn--bordered:hover, .btn--bordered:focus, .btn--bordered:active{
  background: none;
  border: 1px solid $pcolor;
  color: $pcolor;
  box-shadow: none;
}
.btn.btn--white, .btn-light, .btn--light ,
.btn.btn--white:hover, .btn-light:hover, .btn--light:hover {
  background: #fff;
  color: $pcolor;
}
// btn default
.btn--default{
  line-height: 50px;
  min-width: 170px;
}
.btn-default{
  line-height: 50px;
  min-width: 170px;
}
// btn lg
.btn--lg, .btn-lg{
  line-height: 55px;
  font-size: 16px;
  min-width: 195px;
}

// btn md
.btn--md, .btn-md{
  line-height: 45px;
  padding: 0 22px;
  min-width: 150px;
}
.btn--xs{
  line-height: 36px;
  padding: 0 19px;
  font-size: 14px;
}
.btn--fullwidth{
  width: 100%;
}

// btn sm
.btn--sm, .btn-sm{
  line-height: 40px;
  padding: 0 22px;
}

//
.btn--icon{
  span{
    margin-right: 5px;
    line-height: inherit;
    display: inline-block;
  }
}

.btn--faq{
  min-width: 140px;
}

.search-btn{
  background: $pcolor;
  color: #fff;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  min-width: 223px;
  font-size: 16px;
  border: 0;
}

/* btn colors */
.btn.btn--color1, .btn-secondary, .btn--secondary,
.btn.btn--color1:hover, .btn-secondary:hover, .btn--secondary:hover{
  background: $scolor;
  &.btn--bordered{
    background: none;
    border: 1px solid $scolor;
    color: $scolor;
  }
}
.btn.btn--color2, .btn-success, .btn--success,
.btn.btn--color2:hover, .btn-success:hover, .btn--success:hover{
  background: $mcolor1;
  &.btn--bordered{
    background: none;
    border: 1px solid $mcolor1;
    color: $mcolor1;
  }
}
.btn.btn--color3, .btn-warning, .btn--warning,
.btn.btn--color3:hover, .btn-warning:hover, .btn--warning:hover{
  background: $mcolor2;
  &.btn--bordered{
    background: none;
    border: 1px solid $mcolor2;
    color: $mcolor2;
  }
}
.btn.btn--color4 ,.btn-info, .btn--info,
.btn.btn--color4:hover ,.btn-info:hover, .btn--info:hover{
  background: $mcolor3;
  &.btn--bordered{
    background: none;
    border: 1px solid $mcolor3;
    color: $mcolor3;
  }
}
.btn.btn--color5, .btn--danger, .btn-danger,
.btn.btn--color5:hover, .btn--danger:hover, .btn-danger:hover{
  background: $mcolor4;
  &.btn--bordered{
    background: none;
    border: 1px solid $mcolor4;
    color: $mcolor4;
  }
}
.btn.btn-dark, .btn--dark,
.btn.btn-dark:hover, .btn--dark:hover{
  background: #000;
  color: #fff;
  &.btn--bordered{
    background: none;
    border: 1px solid #000;
    color: #000;
  }
}